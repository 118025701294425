import React, { useEffect, useRef, useState, Component } from 'react'

import './style.css'
import './responsive-style.css'

import video from '../Components/Imgs/file.mp4'
import main_img from '../Components/Imgs/main-img.webp'
import main_img1 from '../Components/Imgs/main-img1.webp'
import logos from '../Components/Imgs/logos.webp'
// import banner from '../Components/Imgs/banner.webp'
import banner from '../Components/Imgs/banner.gif'
import logos1 from '../Components/Imgs/logos1.webp'
import img1 from '../Components/Imgs/bootom-banner.webp'
import btn2 from '../Components/Imgs/roadmap.png'
import btn21 from '../Components/Imgs/btn21.webp' 
import logo from '../Components/Imgs/logo.webp'
import logo1 from '../Components/Imgs/logo1.webp'
import btn1 from '../Components/Imgs/btn1.webp'
import btn11 from '../Components/Imgs/btn11.webp'
import img11 from '../Components/Imgs/img11.webp'
import whitelist from '../Components/Imgs/whitelist.png'
import home from '../Components/Imgs/home.png'
import homembl from '../Components/Imgs/home-mbl.png'
import wl_mbl from '../Components/Imgs/wl-mbl.png'
import roadmap_mbl from '../Components/Imgs/roadmap_mbl.png'
import mbl_twitter from '../Components/Imgs/mbl_twitter.png'
import mbl_discord from '../Components/Imgs/mbl_discord.png'

import twitter from '../Components/Imgs/twitter.png'
import discord from '../Components/Imgs/discord.png'

import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
require("dotenv").config();

export default function Home() {

    const [userWallet, setUserWallet] = useState(false);

    async function connectWallet(){
        if (await detectEthereumProvider()) {
          window.web3 = new Web3(window.ethereum);
          await window.ethereum.enable();
          const web3 = window.web3;
   
            let metaMaskAccount = await web3.eth.getAccounts();
            metaMaskAccount = metaMaskAccount[0];

            console.log(metaMaskAccount);
            setUserWallet(metaMaskAccount);
        }
    }

    return (
        <div className="banner-page main-wrapper d-flex align-items-center pt-0">
            <video autoPlay muted loop  id="myVideo" src={video}></video>
            <div className="container">
                <div className="row">
                    <div className="col-12 banner-page-top">
                        <img className="main-banner-img" src={main_img} alt="#" />
                        <img className="main-banner-img1 visible-xs" src={main_img1} alt="#" />
                        {/* <a href='https://discord.gg/wUU8UaGP'><img className="social-icon" src={logos} alt="#" /></a> */}
                    </div>
                    <div className="col-12 text-center p t-5">
                        <img className="banner-img1 mt-5" src={banner} alt="banner"/>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className='col-6'>
                    <a href="https://twitter.com/trippyvc?s=11"><img className="btn-img1 visible-xs mr-auto ml-2 m t-5 mb-0" src={twitter} style={{width: '120px', top: '0'}}/></a>
                    </div>
                    <div className='col-6'>
                    <a href="https://discord.gg/wUU8UaGP"><img className="btn-img1 visible-xs ml-auto mr-2 m t-5 mb-0" src={discord} style={{width: '120px', top: '0'}}/></a>
                    </div>
                    <div className="col-12 banner-page-bottom d-flex m t-0" style={{marginTop: "-40px"}}>
                        {/* <a href='https://discord.gg/wUU8UaGP'><img className="social-icon-img1 visible-xs" src={logos1} alt="#" /></a> */}

                        <img className="main-bottom-img hidden-xs" src={img1} alt="#" />
                        <img className="main-bottom-img visible-xs" src={img11} alt="#" />

                        <a href="https://twitter.com/trippyvc?s=11"><img className="btn-img1 ml-5 twitter-btn hidden-xs" src={twitter} /></a>

                        <a href="http://docs.trippyhaseedclub.com/"><img className="btn-img1 hidden-xs" src={btn2} /></a>
                        {/* <a href="http://docs.trippyhaseedclub.com/"><img className="btn-img1 visible-xs mx-auto mt-5 mb-3" src={mbl_twitter} style={{width: '50px'}}/> */}
                        <a href="http://docs.trippyhaseedclub.com/"><img className="btn-img1 visible-xs" src={roadmap_mbl} style={{zIndex:'100', marginTop: '-75px'}}/></a>
                        {/* </a> */}

                        <a href="https://forms.gle/6Gh8tsSLFZJH94Zo6"><img className="logo-img hidden-xs" src={whitelist} alt="#" /></a>
                        <a href="https://forms.gle/6Gh8tsSLFZJH94Zo6"><img className="logo-img visible-xs" src={wl_mbl} alt="#" style={{marginTop: '-10px', top: '-5px'}} /></a>

                        <a href="http://docs.trippyhaseedclub.com/"><img className="btn-img1 hidden-xs" src={home} /></a>
                        <a href="https://trippy.vc/"><img className="btn-img1 visible-xs" src={homembl} style={{zIndex:'100', marginTop: '-75px'}} /></a>

                        <a href="https://discord.gg/wUU8UaGP"><img className="btn-img1 mr-5 twitter-btn hidden-xs" src={discord} /></a>

                    </div>
                </div>
            </div>
        </div>
    );
}
